import { Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { BSPBillCheck_Ticket, UploadPdfData } from "../type/BSPType";

type Props = {
  bspData: BSPBillCheck_Ticket[] | undefined;
  isLoading: boolean;
};

const BSP_Ticket = ({ isLoading, bspData }: Props) => {
  if (!bspData) return null;

  let maxSlNumber = bspData.reduce(
    (max, item) => (item.sl && item.sl > max ? item.sl : max),
    0
  );
  let newSlNumber = maxSlNumber + 1;

  const sorted_date = bspData
    .map((item) => {
      if (!item.hasOwnProperty("sl")) {
        return { ...item, sl: newSlNumber++ };
      }
      return item;
    })
    .sort((e, b) => e.sl - b.sl);

  const ticketMap = new Map();

  sorted_date.forEach((item, index) => {
    const { sl, purchase_price } = item;
    if (!ticketMap.has(sl)) {
      ticketMap.set(sl, { prices: [], firstIndex: index });
    }
    ticketMap.get(sl).prices.push(Number(purchase_price));
  });

  const formattedData = sorted_date?.map((item, index): UploadPdfData => {
    const { sl } = item;
    const ticketData = ticketMap.get(sl);
    const prices = ticketData.prices;

    console.log({ prices });

    const firstIndex = ticketData.firstIndex;

    const rowSpan = firstIndex === index ? ticketData.prices.length : 0;

    const purchase_price_difference =
      prices?.length > 1 ? prices[0] - prices[1] : prices[0];

    return {
      ...item,
      rowSpan,
      purchase_price_difference,
    };
  });

  const columns: ColumnsType<UploadPdfData> = [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "sl",
      align: "center",
      rowScope: "row",
      width: 80,
      onCell: (record, rowIndex) => {
        return {
          rowSpan: record?.rowSpan,
          className: record.rowSpan === 1 ? "singeTableRow" : "white",
        };
      },
    },
    {
      title: "Ticket No",
      dataIndex: "ticket_no",
      key: "ticket_no",
      align: "center",
      rowScope: "row",
      onCell: (record, rowIndex) => ({
        rowSpan: record?.rowSpan,
        className: record.rowSpan === 1 ? "singeTableRow" : "white",
      }),

      render: (curr, data, index) => {
        if (data?.invoice_category_id) {
          return (
            <Link
              to={`../${
                findInvoiceRoute(data?.invoice_category_id) +
                "/" +
                "details" +
                "/" +
                data?.id
              }`}
            >
              {curr}
            </Link>
          );
        } else {
          return curr;
        }
      },
    },

    {
      title: "Issue Date",
      dataIndex: "sales_date",
      key: "sales_date",
      render: (curr) => dayjs(curr).format("DD-MMM-YYYY"),
    },
    {
      title: "Gross Fare",
      dataIndex: "gross_fare",
      key: "gross_fare",
      render: (curr) => formatAmount(curr),
      align: "right",
    },
    {
      title: "Base Fare",
      dataIndex: "base_fare",
      key: "base_fare",
      render: (curr) => formatAmount(curr),
      align: "right",
    },
    {
      title: "Commission Percent",
      dataIndex: "commission_percent",
      key: "commission_percent",
      align: "right",
    },
    {
      title: "Total Commission",
      dataIndex: "commission_percent_total",
      key: "commission_percent_total",
      render: (curr) => formatAmount(curr),
      align: "right",
    },
    {
      title: "AIT",
      dataIndex: "ait",
      key: "ait",
      render: (curr) => formatAmountAbs(curr),
      align: "right",
    },
    {
      title: "Payable Amount",
      dataIndex: "purchase_price",
      key: "purchase_price",
      render: (curr) => (
        <span style={{ color: "#219C90" }}>{formatAmount(curr)}</span>
      ),
      align: "right",
    },

    {
      title: "Difference",
      dataIndex: "purchase_price_difference",
      key: "purchase_price_difference",
      align: "center",
      rowScope: "row",
      width: 80,
      onCell: (record) => {
        return {
          rowSpan: record?.rowSpan,
          className: record.rowSpan === 1 ? "singeTableRow" : "white",
        };
      },
      render(value, record) {
        if (record.rowSpan === 1) {
          return "❌";
        }
        return (
          <Typography.Text style={{ color: "red" }}>
            {value ? value : "✅"}
          </Typography.Text>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (curr) => {
        if (curr === "IATA")
          return (
            <Tag color="blue" style={{ width: 60, textAlign: "center" }}>
              IATA
            </Tag>
          );
        if (curr === "DB")
          return (
            <Tag color="#007bff" style={{ width: 60, textAlign: "center" }}>
              TRABILL
            </Tag>
          );
      },
    },
  ];

  return (
    <div className="bsp-table">
      <Table
        tableLayout="fixed"
        rowClassName={(record) => {
          const { type } = record;
          let className = "";

          if (type === "IATA") {
            className += "IATA_BILL ";
          }

          if (record.rowSpan === 1) {
            className += "singeTableRow";
          }

          return className.trim();
        }}
        // loading={{
        //   spinning: isLoading,
        //   indicator: loadingIndicator,
        // }}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        bordered
        size="small"
        className="invoiceBillingTable"
      />
    </div>
  );
};

export default BSP_Ticket;
